<template>
  <div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <div></div>
        <div>
          <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
          <el-button
            type="primary"
            @click="outExeAll('examGroup')"
            >전체출력</el-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:user_username="slotProps">
          <div>
            {{ slotProps.callback.row.user.username }}
          </div>
        </template>
        <template v-slot:group_name="slotProps">
          <div>
            {{ slotProps.callback.row.group.name }}
          </div>
        </template>
      <template v-slot:exam_title="slotProps">
          <div>
            {{ slotProps.callback.row.exam.title }}
          </div>
        </template>
      <template v-slot:exam_content="slotProps">
          <div v-html="slotProps.callback.row.exam.content"></div>
        </template>
      <template v-slot:is_bool="slotProps">
          <el-tag :type="is_boolTag(slotProps.callback.row.is_bool)">
            {{ is_bool(slotProps.callback.row.is_bool) }}
          </el-tag>
        </template>
        <template v-slot:operation="slotProps">
            <el-tooltip class="item" effect="dark" :content="$t('btnTip').check" placement="top">
             <el-button
              @click="handleClick('check',slotProps.callback.row)"
              icon="el-icon-view"
              type="success"
              size="mini"
            ></el-button>
          </el-tooltip>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import { groupExamList } from "@/api/index";
export default {
  name: "examGroup",
  mixins: [mixin_list(groupExamList)],
  components: {
    myTable,
    page,
  },
  data() {
    return {
      filter: {
        search: "",
      },
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        {
          label: this.$t("exam.user_username"),
          sortable: true,
          prop: "user.username",
          slot: "user_username"
        },
        {
          label: this.$t("exam.group_name"),
          sortable: true,
          slot:"group_name",
          prop: "group.name",
        },
        {
          label: this.$t("exam.exam_title"),
          sortable: true,
          prop: "exam.title",
          slot: "exam_title",
        },
        {
          label: this.$t("exam.exam_content"),
          sortable: true,
          prop: "exam.content",
          slot: "exam_content"
        },
        {
          label: this.$t("exam.is_bool"),
          sortable: true,
          prop: "is_bool",
          slot: "is_bool"
        },
        {
          label: this.$t("operation"),
          prop: "",
          slot: "operation",
        },
      ],
    };
  },
  methods: {
      is_bool(is_bool){
          switch(is_bool){
              case 1:
                  return this.$t('exam.is_bool1')
            case 0:
                  return this.$t('exam.is_bool0')
          }
      },
      is_boolTag(is_boolTag){
          switch(is_boolTag){
              case 1:
                  return 'success'
            case 0:
                return 'danger'
          }
      },
      async handleClick(type, data) {
      switch (type) {
        case "check":
            this.$router.push(`/exam/group/detail?id=${data.id}`)
            break
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text{
  width:80px;
  font-size: 16px;
}
/deep/ label{
  margin-bottom:0!important
}
</style>